import React from 'react';
import OpsPop from '../components/WhoWeAre/OpsPop';
import Layout from '../components/Layout';

function operatingmodel() {
    return (
        <Layout>
            <OpsPop />
        </Layout>
    )
}

export default operatingmodel;
